import React from 'react'

import Layout from "../components/layout"
import SeasonalInfoList from "../components/SeasonalInfo"
import Seo from "../components/seo"

const SeasonalInfo = () => {

    return (
        <Layout>
            <Seo title="British Seasonal Info" />

            <SeasonalInfoList />
        </Layout>
    )
}

export default SeasonalInfo
