const seasonalInfoList = [
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Broccoli",
        "comment": "June to November",
        "months": [
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Brussel Sprouts",
        "comment": "August to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Cauliflower",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Courgettes",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Fennel",
        "comment": "July to October",
        "months": [
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Green Cabbage",
        "comment": "September to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Leeks",
        "comment": "June & July",
        "months": [
            "June",
            "July"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Pak Choi",
        "comment": "May to November",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Parsnips",
        "comment": "October to March",
        "months": [
            "January",
            "February",
            "March",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Red Cabbage",
        "comment": "August to June",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Romanesco",
        "comment": "July to November",
        "months": [
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Squash",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby Turnips",
        "comment": "May to November",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "BABY VEGETABLES",
        "description": "Baby White Cabbage",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Apricot",
        "comment": "July & August",
        "months": [
            "July",
            "August"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Blackberry",
        "comment": "June to November",
        "months": [
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Blackcurrant",
        "comment": "June to August",
        "months": [
            "June",
            "July",
            "August"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Blueberry",
        "comment": "July to August",
        "months": [
            "July",
            "August"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Braeburn Apple",
        "comment": "December to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "December"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Bramley Apple",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Cherry",
        "comment": "June to August",
        "months": [
            "June",
            "July",
            "August"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Cherry Plum",
        "comment": "July & August",
        "months": [
            "July",
            "August"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Gala Apple",
        "comment": "September to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Golden Delicious Apple",
        "comment": "October to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Physalis",
        "comment": "September to December",
        "months": [
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Pinkcurrant",
        "comment": "July & August",
        "months": [
            "July",
            "August"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Quince",
        "comment": "October & November",
        "months": [
            "October",
            "November"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Raspberry",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Redcurrant",
        "comment": "June to August",
        "months": [
            "June",
            "July",
            "August"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Rowanberry",
        "comment": "October & November",
        "months": [
            "October",
            "November"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Royal Gala Apple",
        "comment": "September to March",
        "months": [
            "January",
            "February",
            "March",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Strawberry",
        "comment": "April to November",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Walnut",
        "comment": "September & October",
        "months": [
            "September",
            "October"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Wild Strawberries",
        "comment": "June to September",
        "months": [
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "FRUIT",
        "description": "Williams Pear",
        "comment": "August & September",
        "months": [
            "August",
            "September"
        ]
    },
    {
        "category": "HERBS",
        "description": "Basil",
        "comment": "May to September",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "HERBS",
        "description": "Bayleaf",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Borage",
        "comment": "June to October",
        "months": [
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "HERBS",
        "description": "Chervil",
        "comment": "May to March",
        "months": [
            "January",
            "February",
            "March",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Chives",
        "comment": "April to October",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "HERBS",
        "description": "Coriander",
        "comment": "April to November",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "HERBS",
        "description": "Curly Parsley",
        "comment": "March to November",
        "months": [
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "HERBS",
        "description": "Dill",
        "comment": "May to September",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "HERBS",
        "description": "Edible flowers",
        "comment": "May to September",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "HERBS",
        "description": "Flat Continental Parsley",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Lemon Balm",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "HERBS",
        "description": "Lemon Thyme",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Lovage",
        "comment": "April to November",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "HERBS",
        "description": "Marjoram",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "HERBS",
        "description": "Mint",
        "comment": "April to October",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "HERBS",
        "description": "Oregan",
        "comment": "April to November",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "HERBS",
        "description": "Rosemary",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Sage",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Samphire",
        "comment": "June to August",
        "months": [
            "June",
            "July",
            "August"
        ]
    },
    {
        "category": "HERBS",
        "description": "Savoury",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Sea Kale",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Shiso Leaves",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Sorrel",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Tarragon",
        "comment": "March to September",
        "months": [
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "HERBS",
        "description": "Thyme",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "HERBS",
        "description": "Wild Celery",
        "comment": "June to September",
        "months": [
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Brown Shimiji",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Button Mushroom",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Cepes Mushroom",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Chanterelle Mushroom",
        "comment": "October to March",
        "months": [
            "January",
            "February",
            "March",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Chestnut Mushroom (Paris Brown)",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Cup Mushroom",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Enoki Mushroom",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Flat Mushroom",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Girolle Mushroom",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Osyter Mushroom",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "MUSHROOMS",
        "description": "Shitake Mushroom",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "SALADS",
        "description": "Baby Spinach",
        "comment": "April to October",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Batavia Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Beef Tomato",
        "comment": "March to October",
        "months": [
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Celery",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Chard",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Cherry Tomato",
        "comment": "January to November",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "SALADS",
        "description": "Chicory",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "SALADS",
        "description": "Corn Salad (Lamb's Lettuce)",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Cos Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Cucumber",
        "comment": "January to September",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "SALADS",
        "description": "Frisse Curly Endive Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Iceberg Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Little Gem Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Lollo Bionde Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Lollo Rosso Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Mizuna Leaf",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Oakleaf Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Peppers",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Plum Tomato",
        "comment": "March to October",
        "months": [
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Raddichio Lettuce",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Red Mustard Leaf",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Rocket Lettuce",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "SALADS",
        "description": "Round Lettuce",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "SALADS",
        "description": "Salad Tomato",
        "comment": "February to November",
        "months": [
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "SALADS",
        "description": "Spinach",
        "comment": "April to October",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "SALADS",
        "description": "Watercress",
        "comment": "March to November",
        "months": [
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "SALADS",
        "description": "Wild Rocket Lettuce",
        "comment": "November to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Agria Potato",
        "comment": "September to June",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Alfalfa Sprouts",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Asparagus",
        "comment": "May to September",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Broad Bean",
        "comment": "June to September",
        "months": [
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Brussel Sprout",
        "comment": "August to March",
        "months": [
            "January",
            "February",
            "March",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Bunched Carrots",
        "comment": "May to December",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Butternut Squash",
        "comment": "June to September",
        "months": [
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Carrots",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Cauliflower",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Celeriac",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Chantenay Carrots",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Chillies",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Chinese Leaves",
        "comment": "April to November",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Courgette",
        "comment": "June to October",
        "months": [
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Fennel",
        "comment": "July to November",
        "months": [
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "French Bean",
        "comment": "July to September",
        "months": [
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Fresh Peas",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Garlic",
        "comment": "August to January",
        "months": [
            "January",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Gem Squash",
        "comment": "June to September",
        "months": [
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Green Cabbage",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Horseradish",
        "comment": "June to September",
        "months": [
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Jalapeno Chilli",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Jerulsalem Artichoke",
        "comment": "October to March",
        "months": [
            "January",
            "February",
            "March",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Kale",
        "comment": "July to March",
        "months": [
            "January",
            "February",
            "March",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "King Edward Potato",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Kohl Rabi",
        "comment": "June to November",
        "months": [
            "June",
            "July",
            "August",
            "September",
            "October",
            "November"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Leek",
        "comment": "August to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Mange Tout",
        "comment": "July to September",
        "months": [
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Maris Piper Potato",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Marrow",
        "comment": "May to October",
        "months": [
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Mouli / Daikon",
        "comment": "June to October",
        "months": [
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Onion",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Onion Squash",
        "comment": "June to September",
        "months": [
            "June",
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Pak Choi",
        "comment": "July to September",
        "months": [
            "July",
            "August",
            "September"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Parsnip",
        "comment": "July to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Premiere Potato",
        "comment": "May to August",
        "months": [
            "May",
            "June",
            "July",
            "August"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Primo Cabbage",
        "comment": "April to October",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Pumpkin",
        "comment": "July to March",
        "months": [
            "January",
            "February",
            "March",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Radish",
        "comment": "April to October",
        "months": [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Raw Beetroot",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Red Cabbage",
        "comment": "August to June",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Red Diseree Potato",
        "comment": "August to June",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Red Onion",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Red Rooster Potato",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Romanesco",
        "comment": "June to December",
        "months": [
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Round Shallot",
        "comment": "September to June",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Runner Bean",
        "comment": "June to October",
        "months": [
            "June",
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Salsify",
        "comment": "October to April",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Savoy Cabbage",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Spring Green Cabbage",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Spring Onion",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Swede",
        "comment": "July to May",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Sweetcorn",
        "comment": "July to October",
        "months": [
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Sweetheart Hispi Cabbage",
        "comment": "July to October",
        "months": [
            "July",
            "August",
            "September",
            "October"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "Tenderstem Broccoli",
        "comment": "November to May",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "November",
            "December"
        ]
    },
    {
        "category": "VEGETABLES",
        "description": "White Cabbage",
        "comment": "All year round",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    }
];

export default seasonalInfoList;