import React, { useState, useMemo } from "react"
import seasonalInfoList from "../../utils/seasonalInfoList"
import Pagination from "../pagination";
import { ChevronDownIcon, ChevronUpIcon, } from '@heroicons/react/outline'
import SearchInput from "../searchInput";
import Dropdown from "../dropdown";

const ROWS_PER_PAGE = 10;

const headers = [
    {
        field: 'category',
        label: 'Category',
    },
    {
        field: 'description',
        label: 'Description',
    },
    {
        field: 'comment',
        label: 'Availability',
    }
];

const categories = [
    'BABY VEGETABLES',
    'FRUIT',
    'HERBS',
    'MUSHROOMS',
    'SALADS',
    'VEGETABLES',
];

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const SeasonalInfo = () => {
    const [page, setPage] = useState(0);

    const [categoriesSelected, setCategoriesSelected] = useState([...categories]);
    const isCategorySelected = (value) => categoriesSelected.includes(value);

    const [selectedMonths, setSelectedMonths] = useState([]);
    const isMonthSelected = (value) => selectedMonths.includes(value);

    function onCategoryFilter(category) {
        if (isCategorySelected(category)) {
            setCategoriesSelected(prevState => [...prevState.filter(c => c !== category)])
        }
        else {
            setCategoriesSelected(prevState => [...prevState, category])
        }
    }

    function onMonthFilter(month) {
        if (isMonthSelected(month)) {
            setSelectedMonths(prevState => [...prevState.filter(m => m !== month)])
        }
        else {
            setSelectedMonths(prevState => [...prevState, month])
        }
    }

    const formatString = (str) => str.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
    const [search, setSearch] = useState('');
    const handleSearchChange = (e) => setSearch(formatString(e.target.value));

    const offset = useMemo(() => page * ROWS_PER_PAGE, [page]);

    const [sortBy, setSortBy] = useState('category');
    const [order, setOrder] = useState('asc');
    function onSort(field) {
        if (sortBy === field) {
            setOrder(prevState => prevState === 'asc' ? 'desc' : 'asc');
        }
        else {
            setSortBy(field);
            setOrder('asc');
        }
    }

    function sortFunction(a, b) {
        if (order === 'asc') {
            return (a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : 0);
        }
        return (a[sortBy] > b[sortBy]) ? -1 : ((b[sortBy] > a[sortBy]) ? 1 : 0);
    }


    const info = seasonalInfoList
        .sort(sortFunction)
        .filter((v) => !!categoriesSelected.includes(v.category))
        .filter((v) => selectedMonths.every((m) => !!v.months.includes(m)))
        .filter((v) => search.length > 0 ? formatString(v.description).indexOf(search) > -1 : true)

    const totalPages = useMemo(() => Math.ceil(info.length / ROWS_PER_PAGE), [categoriesSelected, selectedMonths, search]);


    return (
        <section className="py-20">

            <div className="flex flex-col px-3 md:px-8 mx-auto space-y-12 max-w-7xl xl:px-12">

                <h2 className="w-full text-2xl font-bold text-primary text-center sm:text-3xl md:text-4xl">British Seasonal Info</h2>
                <p className="mb-5 text-lg text-gray-700 text md:text-left text-center mx-auto">
                    We have an extensive range of home grown products and when possible we always buy local British produce.
                </p>

                <div className="flex flex-col md:flex-row justify-center items-center mx-auto w-full max-w-2xl">

                    <div className="my-3 md:mx-3 md:my-0">
                        <SearchInput handleChange={handleSearchChange} />
                    </div>

                    <div className="flex space-x-3">
                        <Dropdown
                            object={categories}
                            label='Categories'
                            isSelected={isCategorySelected}
                            onClick={onCategoryFilter}
                        />
                        <Dropdown
                            object={months}
                            label='Months'
                            isSelected={isMonthSelected}
                            onClick={onMonthFilter}
                        />
                    </div>
                </div>


                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block flex justify-center sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden w-full max-w-3xl border-b border-gray-200 sm:rounded-lg">
                                <table className="w-full min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            {headers.map(({ field, label }, idx) => (
                                                <th
                                                    key={field}
                                                    scope="col"
                                                    className={`px-4 md:px-6 py-3 text-left text-xs md:text-sm font-semibold text-gray-500 uppercase tracking-wider cursor-pointer ${idx === 0 ? 'hidden md:flex' : ''}`}
                                                    onClick={() => onSort(field)}
                                                >
                                                    <div className="flex items-center">
                                                        {label}
                                                        {sortBy === field && order === 'asc' && <ChevronDownIcon className="ml-3 h-3 w-3" />}
                                                        {sortBy === field && order === 'desc' && <ChevronUpIcon className="ml-3 h-3 w-3" />}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {info.length > 0 && info.slice(offset, offset + ROWS_PER_PAGE).map((product) => (
                                            <tr key={product.description}>
                                                <td className="px-4 md:px-6 py-3 text-xs text-gray-500 hidden md:flex">{product.category}</td>
                                                <td className="px-4 md:px-6 py-3 text-xs text-gray-500">{product.description}</td>
                                                <td className="px-4 md:px-6 py-3 text-xs text-gray-500">{product.comment}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {info.length < 1 && (
                                    <div className="flex items-center justify-center font-bold py-4">No products found.</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            </div>
        </section>
    )
}

export default SeasonalInfo
